import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { JwtServiceService } from './jwt-service.service';
import { AuthService } from './auth.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { UserService } from './user.service';
import { LayoutService } from '../share-module/layout.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private jwtService: JwtServiceService, private router: Router, private authService: AuthService,private userService:UserService,
     private toastr: ToastrManager,private layoutService:LayoutService
    ) { }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (!this.authService.isTokenExpired()) {
      if (this.authService.getTokeStatus()) {    
        return true;

      }else{
        this.jwtService.deleteToken();
        this.authService.Logout()
        localStorage.clear();
        debugger
        this.router.navigate(['/auth/home'])
        .then(() => {
            this.layoutService.setShowSidebar(false);
            this.layoutService.setShowHeader(false);
          });
        return false;

      }
    }

    if (this.jwtService.getToken()) {
      return true;
    }
  
    console.log('not login');
    debugger
    this.router.navigate(['/auth/home'])
    .then(() => {
        this.layoutService.setShowSidebar(false);
        this.layoutService.setShowHeader(false);
      });
    return false;
  }


  
}
