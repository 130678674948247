import { Component, OnInit, Inject, Renderer2, OnDestroy, Output, EventEmitter, Input, ViewContainerRef } from '@angular/core';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ToastrManager } from 'ng6-toastr-notifications';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
// import { AuthService } from 'angular-6-social-login';
import { AuthenticationService } from '../../app.service';
import { componentServices } from '../component.service';
import $ from 'jquery';
import { Title } from '@angular/platform-browser';
import { staffingAgencyServices } from 'src/app/staffing-agency/staffing-agency.services';

import { Router, RouterLink } from '@angular/router';
import { ShareService } from 'src/app/share-module/share.service';
import { JobseekerService } from 'src/app/JobSeeker/jobseeker.service';
import { environment } from 'src/environments/environment';
import { LayoutService } from 'src/app/share-module/layout.service';

// import { ActivatedRoute, Router, NavigationEnd} from '@angular/router';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit, OnDestroy  {
  preloader: boolean;
  visible = false;
  activeRoute = '/dashboard';
  linkActive = 'dashboard';
  localStoragerData: any = {};
  status: Boolean = false;
  routerPath = '';
  submitted = false;
  CompName = '';
  Website = '';
  firstName = '';
  middlName = '';
  lastName = '';
  email = '';
  password = '';
  loginForm: FormGroup;
  adminloginForm:FormGroup;
  loading = false;
  returnUrl;
  routerClass;
  type:string;
  user:string;
  loginType = '';
  userData: any = {};
  userDetails:any ={};
  userDataShow = false;
  loginMsg = '';
  headerList = 'GN';
  isLogin = 'false';
  staffReg: any;
  loginTypeSelet = 'Login';
  notifyenterpriseData:any;
  notifyData : any;
  announceData: any;
  announceToggle = false;
  announceLIstData = false;
  img_path = '';
  userLoginData : any ={};
  isCollapsed = true;
  isCollapsedMenu = false;
  islogedCollapsedMenu = false;
  isOn = false;
  isOnsignUp = false;
  isOnjobsk = true;
  isOnentp = false;
  isOnstaffg = false;
  isOnForgot = false;
  isOnreset = false;
  isAdmin=true;
  profileStatus : string;
  isrequire=true;
  announcShow: boolean = false;
  docusignStatus=true;
  isPasswordReset=false
  isPasswordResetCheck='false'
  isTimeSheet: boolean = false;
  jobData: any;
  userDataCheck: any = {};
  todayDate = new Date;
  diffDays: number;
  remainingdays: number;
  showPasswordWarningAfterExpire : boolean=false;
  forgateRes: any;
  @Input() open = new EventEmitter();

  constructor(private _router: Router,
    private router: Router,
    private renderer: Renderer2,
    private API: AuthenticationService,private componentAPI: componentServices,
    // private socialAuthService: AuthService,
    private shareservice:ShareService,
    private toastr: ToastrManager, private AuthenticationService: AuthenticationService, private JSApi:JobseekerService ,private datePipe:DatePipe,private layoutService:LayoutService) {
    this.AuthenticationService.jwtTokenExpCheck();

    this.locatstoreData().then((response: any) => {
      if (this.isLogin === 'true' && response === null) {
        this.logout();
      } else if (this.isLogin === 'true' && response != null) {
        //this.getJobStatus();
        // this.staffingNotificationlist(1, 5);
        // this.enterpriseNotificationlist(1, 5);

        //this.getLoginDetail();
        // this.componentAPI.headernotification(response.data.access_token, response.data.credentials.userType).subscribe((res: any) => { 
        //   if (res.message === 'Failed to authenticate token.') {
        //     this.logout();
        //   }
        // });
      } else {

      }
    });

    this.isLogin = localStorage.getItem('islogin');
    if (this.isLogin === 'true') {
      this.commonPasswordDetails();
    }
    if(this.isPasswordResetCheck=='true') { this.isPasswordReset=true } else { this.isPasswordReset=false }
  }

  ngOnInit() {
    setTimeout(() => {
   this.preloader = true;
      this.isLogin = localStorage.getItem('islogin');
      this.userData = JSON.parse(window.localStorage.getItem('udata'));
      this.userDataShow = true;
      if (this.isLogin === 'true') {
        this.headerList = this.userData.data.credentials.userType;
        this.preloader = false;
        this.img_path = `${this.API.url}/api/auth/image/${this.userData.data.credentials.profileImage}`;
        console.log('this.headerList',this.headerList);
        //NotificATION TRIGGRE ON LOAD
        // if (window.location.pathname.includes('dashboard') === true) {
        //   this.preloader = false;
        //    switch (this.headerList) {
        //     case 'SA': {
        //       this.router.navigate(['/st/staff/dashboard']);
        //       break;
        //     }
        //     case 'EP': {
        //       this.router.navigate(['/ep/enterprise/dashboard']);
        //       break;
        //     }
        //     case 'JS': {
        //       this.router.navigate(['/js/jobseeker/dashboard']);
        //       break;
        //     }
        //     case 'AD': {
        //       this.router.navigate(['/ad/admin/dashboard']);
        //       break;
        //     }
        //   }
        // }   
        //  else{
        //   this.preloader = false;
        //   this.img_path = `${this.API.url}/api/auth/image/${this.userData.data.credentials.profileImage}`;
        // }
      } else {
        this.preloader = false;
        this.headerList = 'GN';
      }

      //  set header according to login type Ends
    }, 100);

    if (localStorage.getItem('udata') === undefined && this.isLogin === 'true') {
      localStorage.setItem('islogin', 'false');
      localStorage.removeItem('token');
      localStorage.removeItem('udata');
      this.headerList = 'GN';
      this.routerClass = '';
      this.loginMsg = '';
      this.isLogin = 'false';
      this.loading = false;
      localStorage.clear();
      this.router.navigate(['/auth/home'])
      .then(() => {
          this.layoutService.setShowSidebar(false);
          this.layoutService.setShowHeader(false);
        });
      return false;
    }

    this.routerPath = window.location.pathname;
    if (this.isLogin === 'false' && (this.routerPath.includes('/ep/') === true || this.routerPath.includes('/js/') === true || this.routerPath.includes('/st/') || this.routerPath.includes('/ad/')===true)) {
      localStorage.setItem('islogin', 'false');
      localStorage.removeItem('token');
      localStorage.removeItem('udata');
      this.headerList = 'GN';
      this.routerClass = '';
      this.loginMsg = '';
      this.isLogin = 'false';
      this.loading = false;
      localStorage.clear();
      this.router.navigate(['/auth/home'])
      .then(() => {
          this.layoutService.setShowSidebar(false);
          this.layoutService.setShowHeader(false);
        });
    }
    //this.headerInit();

    this.getLoginDetail();

    this.localStoragerData = JSON.parse(localStorage.getItem('udata'));
    // this.activatedRoute.queryParams.subscribe((p) => {
    //   //console.log(p,"paramsss");
    // });
    this.linkActive="";

  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }
  toggleCollapseMenu() {
    this.isCollapsedMenu = !this.isCollapsedMenu;
  }
  logedCollapseMenu() {
    this.islogedCollapsedMenu = !this.islogedCollapsedMenu;
  }
  popUpreset() {
    this.isOnreset = !this.isOnreset;
  }

  Popuptoggle() {
    if (this.loginType  !==  '') {
      this.isOn = !this.isOn; 
      this.isCollapsed = true;
    } else {
      this.toastr.infoToastr('Please Select login Type!', 'Warning!', {
        position: 'top-right'
      });
    }
  }

  async locatstoreData() {
      this.isLogin = await localStorage.getItem('islogin');
      if(localStorage.getItem('udata')){
      this.localStoragerData = JSON.parse(localStorage.getItem('udata'));
      return Promise.resolve().then(() => {
        return JSON.parse(localStorage.getItem('udata')); 
      });
    }
  }

  PopupsignUptoggle() {
    this.isOnsignUp = !this.isOnsignUp;
  }

  // getJobStatus() {
  //   this.JSApi.getJobStatus().subscribe(res => {
  //     this.jobData = res;
  //     if (this.jobData.success) {
  //       if (this.jobData.data.length > 0) {
  //         for (var i = 0; i < this.jobData.data.length; i++) {
  //           if (this.jobData.data[i].jobstatus === 'Onboarded') {
  //             this.isTimeSheet = true;
             
  //           }
  //         }
  //       }
  //     }
  //   })
  // }

  emitterLogin(val) {
    this.isLogin = val;
  }

  ngOnDestroy() {
    this.renderer.removeClass(document.body, 'modal-close');
  }
  clicked=false;
  logout() {
    this.headerList = 'GN';
    localStorage.setItem('islogin', 'false');
    localStorage.removeItem('token');
    localStorage.removeItem('udata');
    this.userDetails = {};
    this.routerClass = '';
    this.loginMsg = '';
    this.isLogin = 'false';
    this.loading = false;
    localStorage.clear();
    this.toastr.successToastr('Successfully Logout!', 'Success!', {
      position: 'top-right'
    });
    this.router.navigate(['/auth/home'])
    .then(() => {
        this.layoutService.setShowSidebar(false);
        this.layoutService.setShowHeader(false);
      });
  }

  logData() { 
    return new Promise((resolve, reject) => {
      this.API.loginAPI(this.isAdmin ,this.loginForm.value).subscribe(
        res => {
          this.userData = res;
          resolve(res);
        },
        err => {
          reject(err);
        }
      );
    });
  }

  getLoginDetail(){ 
    if(localStorage.getItem('udata')){
      this.userLoginData = JSON.parse(localStorage.getItem('udata'));
      if(this.userLoginData != null){
        if(this.userLoginData.data.credentials.userType == 'EP'){
          this.userLoginData.data.credentials.staffingagencyId = this.userLoginData.data.credentials.AppRefId;
        }
        if(this.userLoginData.data.credentials.userType == 'JS'){
          this.userLoginData.data.credentials.staffingagencyId = this.userLoginData.data.credentials.AppRefId;
        }

        if(this.userLoginData.data.credentials.userType == 'AD'){
          this.userLoginData.data.credentials.staffingagencyId = this.userLoginData.data.credentials.AppRefId;
        }
        if(this.userLoginData.data.credentials.userType == 'SA'){
          this.userLoginData.data.credentials.staffingagencyId = this.userLoginData.data.credentials.AppRefId;
        }
        this.API.getLoginDetail(this.userLoginData.data.credentials.staffingagencyId,this.userLoginData.data.credentials.emailId,this.userLoginData.data.credentials.userType).subscribe(
          (res:any) =>{
            this.userDetails = res;
            this.profileStatus = this.userDetails.data[0].profile_status;   
            if(this.userLoginData.data.credentials.userType == 'SA'){
              this.docusignStatus = this.userDetails.data[0].docuSignStatus;
            }
      });
      } 
    }
  }

  // headerInit() {
  //   $(document).ready(function () {
  //     $('#home').addClass('active');
  //     $('body').on('click', '.header_list_sec li a', function () {
  //       $(window).scrollTop(0);
  //       if ($(this).parent('li').hasClass('staf_hd') || $(this).parent('li').hasClass('login_hd')) {

  //       } else {
  //         $('.header_list_sec li').removeClass('active');
  //         $(this).parent('li').addClass('active');
  //       }
  //     });
  //   });
  // }
 
  clearData() {
    this.isOnsignUp = false;
    //this.initForm();
  } 

  nextpage(){
    this.router.navigate(['/auth/terms']);
  }
  
  toggle(val) {
    this.visible = true;
  }
  RouterSet = (val, e) => {
    e.preventDefault();
    e.stopPropagation();
    this.activeRoute = val;
  }

//  New changes
  activeLinks(val) {
    this.linkActive = val;
  }

  openMenu() {
    this.status = !this.status;
    var element = document.getElementById("accordionSidebar");
    var element2 = document.getElementById("mainwrapper");
    if (this.status) {
      element.classList.remove("toggled");
      element2.classList.remove("closemenu");
    }
    else {
      element.classList.add("toggled");
      element2.classList.add("closemenu");
    }

  }

  commonPasswordDetails() {
    this.userDataCheck = JSON.parse(localStorage.getItem('udata'));
    //console.log('this.user ====', this.userDataCheck)
    let newDate = new Date(this.datePipe.transform(this.userDataCheck.data.credentials.PasswordUpdateOn, 'yyyy-MM-dd'));
    var diff = Math.abs(this.todayDate.getTime() - newDate.getTime());
    this.diffDays = Math.ceil(diff / (1000 * 3600 * 24));
    this.remainingdays=environment.passwordExpiredays-this.diffDays
   if( this.remainingdays<=0) { 
    this.showPasswordWarningAfterExpire=true;
    window.localStorage.setItem('isPasswordReset', 'true');
    this.isPasswordResetCheck=localStorage.getItem('isPasswordReset');
    if(this.isPasswordResetCheck=='true') { this.isPasswordReset=true } else { this.isPasswordReset=false }
    } else {
      this.showPasswordWarningAfterExpire=false;
      this.isPasswordResetCheck=='false';
    }
  }

  navigate(url:String){
    this.router.navigate([url]);
  }
}